//
// pagination
//
//

.pagination {
  .page-item {
    + .page-item {
      margin-left: .5rem;
    }
  }

  .page-link {
    @include transition;
    @include shadow(0, $border-width, $gray-300);
    box-sizing: content-box;
    width: $pagination-line-height;
    line-height: $pagination-line-height;
    text-align: center;
    border-radius: 50%!important;
    font-size: $font-size-sm;
    font-weight: 600;

    &:not(.active) {
      &:hover { 
        @extend %boxed; 
        border: 0;
      }
    }

  }
}


