@import 'boxes';
@import 'icons';
@import 'navbar';
@import 'gallery';
@import 'cover';
@import 'accordions';
@import 'breadcrumbs';
@import 'dropdowns';
@import 'nav';
@import 'card';
@import 'tables';
@import 'list-group';
@import 'pagination';
@import 'masonry';
@import 'alert';
@import 'badge';
@import 'buttons';
@import 'carousel';
@import 'tabs';
@import 'users';
@import '404';
@import 'articles';
@import 'widgets';
@import 'logs';
@import 'code';
@import 'maps';
@import 'video';
@import 'doc';