//
// Video
//

.video-js {
	@include shadow;
	position: absolute;
	width: 100% !important;
	height: 100% !important;
	left: 0;
	top: 0;
	z-index: 50;
	background: transparent;

	.vjs-poster {
		border-radius: $border-radius;
	}
	
	iframe {
		border-radius: $border-radius;
	}
}

.video-3-4 {
	position: relative;
	width: 100%;
	height: 0;
	padding-bottom: 42.6%;
}

.video-16-9 {
	position: relative;
	width: 100%;
	height: 0;
	padding-bottom: 56.25%;
}

.youtube {
	.vjs-control-bar {
		display: none;
	}
}



// play button
.video-js {

	&.vjs-waiting {
		.vjs-big-play-button {
			@include transition;
			@include scale(2);
			opacity: 0;
		}
	}

	.vjs-big-play-button {
		@include transition;
		@include shadow;
		top: 50%;
		left: 50%;
		background: $white;
		opacity: 1;
		width: 5rem;
		height: 5rem;
		margin-top: -2.5rem;
		margin-left: -2.5rem;
		border-radius: 50%;
		border: 0;

		.vjs-icon-placeholder:before {
			@include transition;
			@include translate(-50%,-50%);
			position: absolute;
			top: 50%;
			left: 50%;
			color: $black;
		}
	}

	&:hover {
		.vjs-big-play-button {
			background: $primary;

			.vjs-icon-placeholder:before {
				color: $white;
			}
		}
	}
}

// .video-js .vjs-big-play-button {
// 	top: 50%;
// 	left: 50%;
// 	width: 80px;
// 	height: 80px;
// 	margin: -40px 0 0 -40px;
// 	line-height: 80px;
// 	font-size: 40px;
// 	background: none;
// 	color: $white;
// 	border: 0;
// 	z-index: 5;
// 	@include transition();

// 	&::before {
// 		@include transition();
// 		position: absolute;
// 		top: 50%;
// 		left: 50%;
// 		margin-top: -40px;
// 		margin-left: -40px;
// 		display: block;
// 		width: 80px;
// 		height: 80px;
// 		line-height: 80px;
// 		text-align: center;
// 		cursor: pointer;
// 		border: 2px solid $white;
// 		content: "";
// 		font-size: 20px;
// 		color: $black;
// 		border-radius: 50%;
// 	}
// }

// .video-js:hover .vjs-big-play-button {
// 	background: none;

// 	&::before {
// 		@include scale(1.2);
// 	}
// }