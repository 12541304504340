//
// forms
//
//

.form-group {
	
	label {
		margin-bottom: .25rem;
		font-size: 12px;
		text-transform: uppercase;
		font-weight: 600;
		letter-spacing: $letter-spacing;
	}
}

.form-control {
	@include shadow(5px, $border-width, rgba($border-color, .5));
}


// inverted
.form-control-inverted {
	@include transition;
	border-color: transparent;
	box-shadow: none;
	background: rgba($black, .25);
	color: $white;

	&:focus {
		border-color: transparent;
		background: rgba($black, .5);
	}
}



// input groups
.input-group-text {
	@include transition;
}

.input-group {
	@include shadow;

	> .form-control {
		@include no-shadow;
		border-left-width: 0;
		border-right-width: 0;

		&:first-child { border-left-width: $border-width; }
		&:last-child { border-right-width: $border-width; }
	}

	&:focus-within {
		.input-group-text {
			border-color: $input-focus-border-color;
		}
	}
}


// minimal
.form-control-minimal {
	@include no-shadow;
	border: 0;
}