//
// helpers
//
//


// boxes
[class*="circle"] {
	border-radius: 50%;
}


// typography
.font-weight-semibold {
  font-weight: 600;
}