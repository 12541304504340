//
// Spacing
//


// Gutter
$gutter-list: ();
$gutter-list: map-merge((
  1: 10,
  2: 20,
  3: 30,
  4: 40,
  5: 50,
), $gutter-list);

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    @each $name, $value in $gutter-list {
      .gutter#{$infix}-#{$name} {
        margin: -#{$value/2}px;

        > * {
          margin-bottom: 0!important;
          padding: #{$value/2}px;
        }
      }
    }

  }
}

.gutter-0 {
  padding: 1px 1px 0 0;
  margin-left: 0;
  margin-right: 0;
  
  > * {
    padding: 0;
    margin: -1px -1px 0 0!important;
  }
}