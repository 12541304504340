//
// Buttons
//


.btn-rounded { border-radius: 10rem; }


// top
.btn-frame {
  display: inline-block;
  padding: .5rem;
  border-radius: 50%;
}


.btn {
  position: relative;

  .badge {
    font-size: 1em;
    margin-right: -($card-spacer-x * 1.5);
    margin-left: 1em;
  }
}


// icon
.btn-ico {
  position: relative;
  box-sizing: content-box;
  width: 1.5em;
  height: 1.5em;
  padding: $btn-padding-y;

  &[class*="-sm"] {
    padding: $btn-padding-y-sm;
  }

  &[class*="-lg"] {
    padding: $btn-padding-y-lg;
  }

  &[class*="-rounded"] {
    border-radius: 50%!important;
  }

  i {
    @include translate(-50%,-50%);
    position: absolute;
    top: 50%;
    left: 50%;
  }
}


// inverted button
.btn-inverted {
  background: rgba($white, .1);
  color: $white;

  &:hover {
    background: rgba($white, .15);
  }
}


// basic colors
@each $color, $value in $colors {
  .btn-#{$color} {
    @include button-variant($value, $value);
  }
}

@each $color, $value in $colors {
  .btn-outline-#{$color} {
    @include button-outline-variant($value);
  }
}


// social colors
@each $color, $value in $socials {
  .btn-#{$color} {
    @include button-variant($value, $value);
  }
}

@each $color, $value in $socials {
  .btn-outline-#{$color} {
    @include button-outline-variant($value);
  }
}


// gray colors
@each $color, $value in $grays {
  .btn-#{$color} {
    @include button-variant($value, $value);
  }
}

@each $color, $value in $grays {
  .btn-outline-#{$color} {
    @include button-outline-variant($value);
  }
}